@use '@virginmediao2/momentum-css/react/themes/vmo2';

.host {
  font-family: 'VMO2Momentum', Arial, sans-serif;

  --color-footer: var(--color-gradient-gradient-alpha);
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: normal;
  font-weight: 900;
  src: url('https://a.storyblok.com/f/253875/x/d0371c1117/aeonikpro-black.woff')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: italic;
  font-weight: 900;
  src: url('https://a.storyblok.com/f/253875/x/cce6cee4c6/aeonikpro-blackitalic.woff')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: normal;
  font-weight: 700;
  src: url('https://a.storyblok.com/f/253875/x/f3e08d870d/aeonikpro-bold.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: italic;
  font-weight: 700;
  src: url('https://a.storyblok.com/f/253875/x/2376c835da/aeonikpro-bolditalic.woff')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: normal;
  font-weight: 500;
  src: url('https://a.storyblok.com/f/253875/x/3e341af44c/aeonikpro-medium.woff')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: italic;
  font-weight: 500;
  src: url('https://a.storyblok.com/f/253875/x/d6f4637e7f/aeonikpro-mediumitalic.woff')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: normal;
  font-weight: 400;
  src: url('https://a.storyblok.com/f/253875/x/9ac4924047/aeonikpro-regular.woff')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: italic;
  font-weight: 400;
  src: url('https://a.storyblok.com/f/253875/x/0059c1c623/aeonikpro-regularitalic.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: normal;
  font-weight: 300;
  src: url('https://a.storyblok.com/f/253875/x/b69337e8b5/aeonikpro-light.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'VMO2Momentum';
  font-style: italic;
  font-weight: 300;
  src: url('https://a.storyblok.com/f/253875/x/8dd17d7c70/aeonikpro-lightitalic.woff2')
    format('woff2');
  font-display: swap;
}
